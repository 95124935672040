<template>
  <div>
    <ul v-if="orderItems.length > 0" class="border border-hr rounded py-8 px-12">
      <div v-for="(order, idx) in orderItems" :key="idx">
        <li class="flex items-center justify-between">
          <div class="flex flex-1 items-center w-0">
            <span class="h-32 px-5 min-w-32 flex items-center justify-center rounded-full bg-green-200">{{ order.line_item.quantity || 0 }}</span>

            <p class="ml-8 whitespace-no-wrap truncate">{{ order.line_item.title }}</p>
          </div>

          <div class="flex flex-shrink-0 items-center ml-16">
            <p class="whitespace-no-wrap">Paid amount: kr. {{ getPrice(order.line_item) | formaNumber(2) }}</p>
            <el-input
              v-if="!isStatusFulfilled"
              :value="fulfillAll ? order.line_item.fulfillable_quantity : ''"
              placeholder="Amount"
              class="ml-10"
              readonly
              input-class="rounded w-64"
              @input="order.line_item.fulfillable_quantity = $event"
            />
            <p v-else class="ml-10">Amount: {{ order.line_item.fulfillable_quantity }}</p>
          </div>
        </li>
        <li class="flex flex-col text-grey-400">
          SKU {{ order.line_item.sku }}
        </li>
        <li class="flex flex-col">
          {{ order.line_item.variant_title }}
        </li>
        <li class="flex flex-col">
          <span class="text-xs">Unit price: {{ order.line_item.price }},
            price without discount: {{ +order.line_item.price * +order.line_item.quantity | formaNumber(2) }},
            price with discount: {{ getPrice(order.line_item) | formaNumber(2) }}
          </span>
        </li>
        <li v-for="(property, propIdx) in order.line_item.properties" :key="propIdx" class="flex flex-col">
          <div v-if="property.name[0] !== '_'" class="w-full">{{ property.name }}: {{ property.value }}</div>
        </li>
        <li v-if="getBookingNames(order.line_item)">
          <div style="white-space: pre;margin-top: 10px">Personnavne på booking: <br>{{ getBookingNames(order.line_item) }}</div>
        </li>
        <hr v-if="idx !== orderItems.length - 1" class="my-8 -mx-12 border-none h-1 bg-hr">
      </div>
    </ul>

    <!-- <div class="flex">
      <el-checkbox v-if="!isStatusFulfilled" v-model="fulfillAll" disabled class="mt-8"><span>Fulfill all</span></el-checkbox>
    </div> -->
  </div>
</template>

<script>
const ElInput = () => import('@/components/shared/ELInput')

export default {
  name: 'OrderItems',

  components: { ElInput },

  props: {
    orderItems: { type: Array, default: () => [] },
    isStatusFulfilled: { type: Boolean, default: false }
  },

  data () {
    return {
      fulfillAll: true
    }
  },

  methods: {
    getPrice (item) {
      let lineDiscount = item.total_discount
      if (item.discount_allocations && item.discount_allocations.length) {
        lineDiscount = item.discount_allocations.reduce((initial, next) => initial + parseFloat(next.amount), 0)
      }
      return (+item.price * +item.quantity) - lineDiscount
    },
    getBookingNames (item) {
      console.log('getBookingNames', item.properties)
      const bookingNames = item.properties
        .filter(property => property.name === '_bookingInfo')
        .map(property => property.value)
        .join(',')
      return `${bookingNames}`
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
